@import url("https://use.typekit.net/yjs3srf.css");

:root {
  --purple: #6964ff;
  --orange: #f09566;
  --green: #56ff56;
}

html,
body,
#root {
  height: 100vh;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: franklin-gothic-urw, sans-serif;
  font-weight: 400;
  font-style: normal;
  background: black;
  color: var(--purple);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

a[href^="http://maps.google.com/maps"]
{
  display: none !important;
}
a[href^="https://maps.google.com/maps"]
{
  display: none !important;
}

.gmnoprint a,
.gmnoprint span,
.gm-style-cc {
  display: none;
}
.gmnoprint div {
  background: none !important;
}
