.App {
  height: 100%;
}

.header {
  font-size: 30px;
  text-transform: uppercase;
  /* letter-spacing: 37px; */
  /* margin-left: 37px; */
  color: var(--orange);
  text-align: center;
  display: flex;
  justify-content: center;
}

.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.grid-container {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;
  max-width: 390px;
  /* margin: 30px; */
}

.cell {
  flex: 0 0 15%;
  background: var(--purple);
  margin: 10px;
  transition: background 500ms;
}

.cell.selected {
  background: var(--green);
}

.smiler-container {
  display: flex;
  justify-content: center;
  position: absolute;
  left: 50%;
  top: 50%;
  width: 150px;
  height: 150px;
  align-items: center;
  box-sizing: border-box;
  margin-left: -75px;
  margin-top: -75px;
  pointer-events: none;
  z-index: -1;
}

.smiler-container > svg {
  opacity: 0;
  transition: opacity 500ms;
}

.smiler-container.show > svg {
  opacity: 1;
}

.smiler-container.show {
  z-index: 1;
}

.smiler-container * {
  transition: stroke 500ms, fill 500ms;
}
circle.white {
  fill: white;
}

path.white,
ellipse.white {
  stroke: white;
}
circle.red {
  fill: red;
}

path.red,
ellipse.red {
  stroke: red;
}

circle.green {
  fill: var(--green);
}

path.green,
ellipse.green {
  stroke: var(--green);
}

.wrong {
  animation: wrong 500ms 3;
}

.right {
  animation: right 500ms 3;
}

@keyframes wrong {
  0% {
    background: rgb(255, 0, 0);
  }
  100% {
    background: rgb(255, 255, 127);
  }
}

@keyframes right {
  0% {
    background: rgb(82, 177, 255);
  }
  100% {
    background: rgb(255, 49, 255);
  }
}

.pulse {
  animation: pulse 500ms infinite alternate;
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(1.5);
  }
}

.marker-pulse {
  animation: marker-pulse 500ms infinite alternate;
}

@keyframes marker-pulse {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0.5);
  }
}
